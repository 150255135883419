export const environment = {
  production: true,
  noindex: false,
  baseUrl: "",
  baseUrlSsr: "https://pirog.io",
  seoUrl : "https://pirog.io",
  isExplorerActivated: true,
  GOOGLE_CLIENT_ID: "727063448365-biq1vbd0tc272tpsm30lckqfr5hbgje2.apps.googleusercontent.com",
  AWS_ACCESS_KEY_ID: "AKIAVGXAISXV2OUP2WHR",
  AWS_SECRET_ACCESS_KEY: "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",
  AWS_REGION: "eu-west-3",
  OPS_BUCKET: "makit-pirog-prd-shared-ops",
  PITRANSFER_BUCKET: "makit-pirog-prd-pitransfer-inputs",
  SHARED_FRONTS_BUCKET: "makit-pirog-prd-shared-fronts",
  S3_PREFIXE: "clients/apps",
  PI_TRANSFER_GB_LIMIT: 2,
  PI_TRANSFER_RENAME_GB_LIMIT: 0.01,
  stripePortalUrl: "https://billing.stripe.com/p/login/dR67u53Im52FePu3cc",
  stripePublicKey: "pk_live_51McbbFFXZHkCQxfI9s9hGqXYI4Mqu3tpoWCpSmfiPzvdc7mDAoF1b3I34hAajVDCHJZrcSIyK7EFbKJpNYJvF3RE00pjcAcouN",
  RECAPTCHA_SECRET_KEY:"6LeGEFkpAAAAAAX4f_1jBTp5oVGMPGPk2Hivkq81",
  PAGE_BUILDER_URL:"pirog.io",
  ID_CLOUDFRONT_CLIENT: 'https://d17n9xv4irg400.cloudfront.net',
  GTM_ID:"GTM-PD8H6LK7",
  GTM_AUTH:"cQtF8fJ2Jq17OkKplXlE6Q",
  GTM_ENV:"prd",
  GTM_RESSOURCE_PATH:"https://pirog.io"
};
