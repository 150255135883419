<footer class="footer d-flex flex-column justify-content-between bg-custom">
  <div class="container-fluid">
    <div class="row pt-4 justify-content-center">
      <div class="col-md-3 text-center">
        <div>
          <img src="../../../../assets/img/logo/logo_white.png" alt="Pirog Logo" class="w-50 m-3 logo-custom"/>
        </div>
        <span class="btn-inner--icon"><i class="display-4 m-1 fab fa-instagram text-white"></i></span>
        <span class="btn-inner--icon"><i class="display-4 m-1 fab fa-facebook text-white "></i></span>
        <span class="btn-inner--icon"><i class="display-4 m-1 fab fa-linkedin text-white"></i></span>
      </div>
      <div class="col-md-2 mt-2">
        <p class="text-primary-light-2">{{ 'components.footer.products.title' | translate }}</p>
        <p *ngFor="let template of templates$ | async" class="ml-3">
          <a class="text-white" [routerLink]="['/store', template.name]">{{ template.name }}</a>
        </p>
      </div>
      <div class="col-md-2 mt-2">
        <p class="text-primary-light-2">{{ 'components.footer.ressources.title' | translate }}</p>
        <p class="ml-3"><a class="text-white" [routerLink]="['/doc']">{{ 'components.footer.ressources.doc' | translate }}</a></p>
        <p class="ml-3"><a class="text-white" [routerLink]="['/faq']">{{ 'components.footer.ressources.faq' | translate }}</a></p>
        <p class="ml-3"><a class="text-white" [routerLink]="['/blog']">{{ 'components.footer.ressources.blog' | translate }}</a></p>
      </div>
      <div class="col-md-2 mt-2">
        <p class="text-primary-light-2">{{ 'components.footer.about.title' | translate }}</p>
        <p class="ml-3"><a class="text-white" [routerLink]="['/pricing']">{{ 'components.footer.about.pricing' | translate }}</a></p>
        <p class="ml-3"><a class="text-white" [routerLink]="['/contact']">{{ 'components.footer.about.contact' | translate }}</a></p>
      </div>
      <div class="col-md-2 mt-2">
        <p><a class="text-white mt-3" [routerLink]="['/login']">{{ 'components.footer.account.login' | translate }}</a></p>
        <p><a class="text-white" [routerLink]="['/signin']">{{ 'components.footer.account.signin' | translate }}</a></p>
      </div>
    </div>

    <div class="row justify-content-between mt-4">
      <div class="col-md-6">
          <div class="copyright text-center text-white">
            &copy; {{ test | date: "yyyy" }}
            <span class="font-weight-bold ml-1">
              A Makit Technology Company
            </span>
          </div>
      </div>
      <div class="col-md-6">
        <div class="copyright text-center text-white">
          <span class="font-weight-bold ml-1 d-inline">
            <a class="text-white mx-3" [routerLink]="['/mentions']">{{ 'components.footer.mentions' | translate }}</a>
            <a class="text-white mx-3" [routerLink]="['/cgu']">{{ 'components.footer.cgu' | translate }}</a>
            <a class="text-white mx-3" [routerLink]="['/cookies']">{{ 'components.footer.cookies' | translate }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</footer>
